const tuple = <T extends string[]>(...args: T) => args;

export interface ChainConfig {
  chainId: string;
  nodeUrl: string;
}

export const ChainConfigs: ChainConfig[] = [
  {
    chainId: "testnet",
    nodeUrl: "https://testnet-api-http.aergo.io",
  },
  {
    chainId: "mainnet",
    nodeUrl: "https://mainnet-api-http.aergo.io",
  },
  {
    chainId: "alpha",
    nodeUrl: "https://alpha-api-http.aergo.io",
  },
];

export const PublicChainIds = tuple("mainnet", "testnet", "alpha");
export type PublicChainId = (typeof PublicChainIds)[number];

export function isPublicChainId(chainId: string): chainId is PublicChainId {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return PublicChainIds.indexOf(chainId as any) !== -1;
}

export const PublicChainData: Record<
  PublicChainId,
  { label: string; chainId: string; apiUrl: string; explorerUrl: string; nodeUrl: string; contractAddress: string }
> = {
  mainnet: {
    label: "mainnet",
    chainId: "aergo.io",
    apiUrl: "https://api2-mainnet.aergoscan.io/v2",
    explorerUrl: "https://mainnet.aergoscan.io",
    nodeUrl: "https://mainnet-api-http.aergo.io",
    contractAddress: "AmgBqP4pnxYxhwMqbgcefGiq3EvXJbULMRRjweJuQye7krMq5EJC",
  },
  testnet: {
    label: "testnet",
    chainId: "testnet.aergo.io",
    apiUrl: "https://api2-testnet.aergoscan.io/v2",
    explorerUrl: "https://testnet.aergoscan.io",
    nodeUrl: "https://testnet-api-http.aergo.io",
    contractAddress: "AmgXmdvyETe2XbWhQzUo6SAM18myQqd9DTS8K6wuarUXHKFbZceU",
  },
  alpha: {
    label: "alpha",
    chainId: "alpha.aergo.io",
    apiUrl: "https://api2-alpha.aergoscan.io/v2",
    explorerUrl: "https://alpha.aergoscan.io",
    nodeUrl: "https://alpha-api-http.aergo.io",
    contractAddress: "AmhHSquCXUQkNaPjc6p9zeK1z3hEywoy98Bvit29bZNgxyfoMqZr",
  },
};

export default {
  ChainConfigs,
  PublicChainIds,
  PublicChainData,
};
