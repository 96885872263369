import { isPublicChainId, PublicChainData } from "../config";

const prod = {
  url: {
    API_URL: "https://dev.silvermine.com",
  },
};

const dev = {
  url: {
    API_URL: "https://localhost:3000",
  },
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;

export function getNodeUrl(chainId: string): string {
  if (!isPublicChainId(chainId)) {
    return "";
  }
  const base = PublicChainData[chainId].nodeUrl;
  return base;
}

export function getExplorerUrl(chainId: string, path: string): string {
  if (!isPublicChainId(chainId)) {
    return "";
  }
  const base = PublicChainData[chainId].explorerUrl;
  return `${base}/${path}`;
}

export function getApiUrl(chainId: string, path: string): string {
  if (!isPublicChainId(chainId)) {
    return "";
  }
  const base = PublicChainData[chainId].apiUrl;
  return `${base}/${path}`;
}

export function getContractAddress(chainId: string): string {
  if (!isPublicChainId(chainId)) {
    return "";
  }
  const base = PublicChainData[chainId].contractAddress;
  return base;
}

export function getAllUrl(chainId: string) {
  if (!isPublicChainId(chainId)) {
    return null;
  }
  const base = { ...PublicChainData[chainId] };
  return base;
}

export default {
  config,
  getNodeUrl,
  getExplorerUrl,
  getApiUrl,
  getContractAddress,
  getAllUrl,
};
